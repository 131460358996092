import * as React from 'react';
import {
  Datagrid,
  DateField,
  List,
  TextField,
  useRecordContext,
  useDataProvider,
  useAuthProvider,
  Loading,
  BooleanField,
  useRefresh,
  useNotify,
  TopToolbar,
  Button,
  usePaginationState,
  useListContext,
  useListController,
} from 'react-admin';

import { NoteShowContent } from './NoteShow';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { NoteCreatedBy } from '../modules/notes/noteCreatedBy';
import { updateReadNote } from '../modules/notes/notesApi';
import { Box, Fab, Tooltip, Typography } from '@mui/material';
import { loggedUser } from '../authProvider';

const CreatedByField = ({ label }: any) => {
  const record = useRecordContext();
  if (!record) return null;

  return (
    <NoteCreatedBy
      data={{
        firstName: record.createdBy.firstName,
        lastName: record.createdBy.lastName,
        createdAt: record.createdAt,
      }}
    />
  );
};

const ReadField = ({ label }: any) => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();

  if (!record) return null;

  const handleSetReadDone = () => {
    updateReadNote({ noteIds: [record._id] })
      .then(() => {
        refresh();
        notify('Note marked as read', { type: 'info' });
      })
      .catch((error) => {
        notify(`Error: ${error.message}`, { type: 'warning' });
      });
  };

  return (
    <Box>
      {record.read ? (
        <CheckIcon color="success" />
      ) : (
        <Tooltip title="Mark as Read">
          <Fab
            color="primary"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              handleSetReadDone();
            }}
            sx={{
              width: 32,
              height: 32,
              minHeight: 32,
              minWidth: 30,
            }}
          >
            <CheckIcon />
          </Fab>
        </Tooltip>
      )}
    </Box>
  );
};

const ListActions = () => {
  const refresh = useRefresh();
  const notify = useNotify();
  const { data = [] } = useListContext();
  const dataProvider = useDataProvider();
  usePaginationState();

  const unreadNoteIds = data
    ?.filter((note) => !note.read)
    ?.map((note) => note._id);

  const handleMarkAllAsRead = async () => {
    try {
      if (unreadNoteIds.length > 0) {
        await updateReadNote({ noteIds: unreadNoteIds });
        refresh();
        notify('All visible unread notes marked as read', { type: 'info' });
      } else {
        notify('No unread notes to mark', { type: 'info' });
      }
    } catch (error: any) {
      notify(`Error: ${error.message}`, { type: 'warning' });
    }
  };

  return (
    <TopToolbar>
      <Button
        label="Mark All as Read"
        onClick={handleMarkAllAsRead}
        startIcon={<DoneAllIcon />}
        disabled={unreadNoteIds.length === 0}
      />
    </TopToolbar>
  );
};

const NoteList = () => {
  const [userId, setUserId] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const authProvider = useAuthProvider();

  React.useEffect(() => {
    const fetchIdentity = async () => {
      try {
        const identity = await authProvider?.getIdentity?.();
        if (identity && 'userId' in identity) {
          setUserId(identity.userId);
        }
      } catch (error: any) {
        console.error('Failed to fetch user identity:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchIdentity();
  }, [authProvider]);

  if (loading) {
    return <Loading />;
  }

  return (
    <List
      exporter={false}
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={25}
      queryOptions={{ meta: { userId } }}
      actions={<ListActions />}
    >
      <Datagrid
        bulkActionButtons={false}
        optimized
        rowClick="expand"
        expand={<NoteShowContent />}
      >
        <TextField
          source="text"
          label="Note Text"
          sx={{
            maxWidth: 400,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            display: 'block',
            textOverflow: 'ellipsis',
          }}
        />
        <TextField source="itemId" label="Item ID" />
        <TextField source="section" label="Section" />
        <CreatedByField label="Created By" />
        <DateField
          source="createdAt"
          label="Created At"
          showTime
          options={{
            month: 'short',
            day: 'numeric',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
          }}
        />
        <DateField
          source="updatedAt"
          label="Updated At"
          showTime
          options={{
            month: 'short',
            day: 'numeric',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
          }}
        />

        <ReadField label="Read" />
      </Datagrid>
    </List>
  );
};

export default NoteList;
