import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Drawer,
  Fab,
  IconButton,
  Input,
  InputBase,
  Pagination,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { createRef, useEffect, useRef, useState } from 'react';
import { Add, Delete as DeleteIcon, EditNote } from '@mui/icons-material';
import NavigationIcon from '@mui/icons-material/Navigation';
import {
  Loading,
  useAuthState,
  useGetIdentity,
  useGetRecordId,
  useStore,
  useStoreContext,
} from 'react-admin';
import authProvider, { loggedUser } from '../../authProvider';
import {
  createNote,
  deleteNote,
  getNotes,
  updateNote,
  updateReadNote,
} from './notesApi';
import { useLocation, useNavigate } from 'react-router';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { formatDistanceStrict } from 'date-fns';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import NoteBox from './noteBox';
const styles = {
  floatIcon: {
    // right: '1.5em',
    // bottom: '.2em',
    // position: 'fixed',
  },
};

const notesEnabledPages = [
  'cards/*',
  'orders/*',
  'payees/*',
  'customers/*',
  'cards',
  'orders',
  'payees',
  'customers',
];
function isNotesEnabled(url: any) {
  return notesEnabledPages.some((pattern) => {
    if (pattern.endsWith('/*')) {
      return (
        url.startsWith(pattern.slice(0, -2)) && url.length > pattern.length - 2
      );
    }
    return url === pattern;
  });
}

const splitLocationPath = (path: string): string[] => {
  console.log(path);
  const [, section, itemId] = path.split('/');
  const route = [section, itemId !== 'create' ? itemId : ''];

  return isNotesEnabled(`${section}${itemId ? '/*' : ''}`) ? route : [];
};

export const Notes = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [[section, itemId], setCurrentPath] = useStore('currentPath', []);

  const navigate = useNavigate();
  useEffect(() => {
    setCurrentPath(() => splitLocationPath(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    if (location.state?.from === 'dashboard') {
      setOpen(true);
      navigate(location.pathname, { replace: true });
    }
  }, [location.state?.from]);

  const toggleDrawer = () => {
    setOpen((o) => !o);
  };

  return section ? (
    <>
      <IconButton
        // color="primary"
        aria-label="edit"
        sx={styles.floatIcon}
        onClick={toggleDrawer}
      >
        {/* <Badge badgeContent={4} color="error" variant="standard"> */}
        <NoteAltIcon />
        {/* </Badge> */}
      </IconButton>
      <Drawer
        open={open}
        onClose={toggleDrawer}
        anchor="right"
        PaperProps={{
          style: {
            borderTopLeftRadius: 10,
          },
        }}
      >
        <NotesSection />
      </Drawer>
    </>
  ) : null;
};

const NotesSection = ({}) => {
  const [[section, itemId], setCurrentPath] = useStore('currentPath', []);
  const [allNotes, setAllNotes] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const scrollRef = useRef();
  const pageSize = 25;
  const [pageDetails, setPageDetails] = useState<any>({});
  const [notesLoading, setNotesLoading] = useState(false);
  const unreadNotes = allNotes
    .filter((item: any) => {
      const readDone =
        item.createdBy?.userId == loggedUser.userId ||
        item.readBy?.some(({ user }: any) => user.userId == loggedUser.userId);
      return !readDone;
    })
    .map((item: any) => item._id);
  console.log({ unreadNotes });
  const _getNotes = () => {
    setNotesLoading(true);
    getNotes({ section, itemId, currentPage: currentPage - 1, pageSize }).then(
      (data: any) => {
        setAllNotes(data.data || []);
        setPageDetails({ total: data.total, range: data.range });
        setNotesLoading(false);
      }
    );
  };

  useEffect(() => {
    if (section) _getNotes();
  }, [section, itemId, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    setAllNotes([]);
  }, [section, itemId]);

  const isNewNoteExist = allNotes.some((i: any) => i._id == -1);

  const deleteNewlyAddedNote = () => {
    setAllNotes((allNotes: any) => allNotes.filter((i: any) => i._id !== -1));
  };

  const setAllReadDone = () => {
    updateReadNote({ noteIds: unreadNotes }).then(() => {
      _getNotes();
    });
  };
  return (
    <Box
      sx={{
        width: '360px',
        padding: '1em 1em 0 1em',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 1em)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Box>
            <Typography fontWeight={500} fontSize={18}>
              Notes{section ? ` (${section})` : ''}
            </Typography>
          </Box>
          {itemId && (
            <Typography
              variant="h6"
              fontWeight={400}
              fontSize={'.8em'}
              color={'text.secondary'}
            >
              ID:{itemId}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          {!isNewNoteExist && unreadNotes.length > 0 && (
            <Tooltip title="Mark All as Read">
              <Fab
                // startIcon={<Add />}
                // variant="contained"
                color="success"
                size="small"
                onClick={setAllReadDone}
              >
                <DoneAllIcon />
              </Fab>
            </Tooltip>
          )}
          {!isNewNoteExist && itemId && (
            <Tooltip title="New Note">
              <Fab
                // startIcon={<Add />}
                // variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  setAllNotes([
                    {
                      _id: -1,
                      createdBy: {
                        firstName: loggedUser.firstName,
                        lastName: loggedUser.lastName,
                      },
                    },
                    ...allNotes,
                  ]);
                }}
              >
                <Add />
              </Fab>
            </Tooltip>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          flex: 1,
          overflow: 'auto',
          scrollbarWidth: 'none',
          mt: 2,
          pb: 2,
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        ref={scrollRef}
      >
        {notesLoading ? (
          <>
            <Stack gap={3}>
              <Skeleton
                variant="rectangular"
                sx={{
                  minHeight: 150,
                  borderRadius: 0.5,
                }}
              />
              <Skeleton
                variant="rectangular"
                sx={{
                  minHeight: 150,
                  borderRadius: 0.5,
                }}
              />
              <Skeleton
                variant="rectangular"
                sx={{
                  minHeight: 150,
                  borderRadius: 0.5,
                }}
              />
              <Skeleton
                variant="rectangular"
                sx={{
                  minHeight: 150,
                  borderRadius: 0.5,
                }}
              />
            </Stack>
          </>
        ) : allNotes.length > 0 ? (
          <>
            {allNotes.map((note: any, index: number) => (
              <NoteBox
                note={note}
                index={index}
                key={note._id}
                callback={_getNotes}
                deleteNewlyAddedNote={deleteNewlyAddedNote}
              />
            ))}
            {/* <Button onClick={() => setCurrentPage((page) => page + 1)}>
              Show More
            </Button> */}
          </>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: '30vh',
            }}
          >
            <NoteAltIcon
              sx={{ height: '80px', width: '80px', color: 'text.light' }}
            />

            <Typography variant="h5" color={'text.light'}>
              No Notes Found
            </Typography>
          </Box>
        )}
      </Box>

      {pageDetails.total > 0 && (
        <Stack
          direction={'row'}
          spacing={1}
          sx={{ p: 2 }}
          alignItems={'center'}
          marginLeft={'auto'}
        >
          <Box sx={{ fontSize: '14px' }}>
            {pageDetails.range
              .split('-')
              .map((i: any) => parseInt(i) + 1)
              .join(' - ')}{' '}
            of {pageDetails.total}
          </Box>
          <Pagination
            size="small"
            // sx={{ p: 1 }}
            count={Math.ceil(pageDetails.total / pageSize)}
            // color="primary"
            page={currentPage}
            onChange={(e, value) => {
              setCurrentPage(value);
            }}
          />
        </Stack>
      )}
    </Box>
  );
};
